import { graphql, useStaticQuery } from "gatsby";

export const ResturangQuery = tema => {
  let query = useStaticQuery(
    graphql`
      query {
        allContentfulRestaurangTeman {
          edges {
            node {
              node_locale
              seoTitel
              seoDescription
              rubrik
              heroBild {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
                file {
                  contentType
                  url
                }
              }
              heroUnderRubrik {
                heroUnderRubrik
              }
              tematGllerFr
            }
          }
        }
        allContentfulRestaurangSida {
          edges {
            node {
              heroRubrik
              node_locale
              Synligataggar
              seoTitel
              seoDescription
              heroUnderRubrik {
                heroUnderRubrik
              }
              introText {
                childContentfulRichText {
                  html
                }
              }
              introTextbrunch {
                childContentfulRichText {
                  html
                }
              }
              introTextfika {
                childContentfulRichText {
                  html
                }
              }
              introTextjulbord {
                childContentfulRichText {
                  html
                }
              }
              introTextlunch {
                childContentfulRichText {
                  html
                }
              }
              introTextdrinkar {
                childContentfulRichText {
                  html
                }
              }
              introTextmiddag {
                childContentfulRichText {
                  html
                }
              }
              heroBild {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid_withWebp
                }
                file {
                  contentType
                  url
                }
              }
            }
          }
        }
      }
    `
  );

  const data = {
    allContentfulRestaurangTeman: {
      edges: query.allContentfulRestaurangTeman.edges.filter(
        i => i.node.tematGllerFr[0] === tema
      )
    },
    allContentfulRestaurangSida: query.allContentfulRestaurangSida,
  };

  return data;
};
