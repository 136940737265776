import React from "react";
import * as Global from "../css/globalStyle"
import {useGlobal} from 'reactn'
 

const Bokabord = ({lang}) => {
  let [english] = useGlobal("English");
  return (
    <Global.CenterDivRow >
      <Global.bokabord
        id={'booknow'}
        data-hash={"75c6c22420afb1a93770ba2810b775a8"}
        className={"waiteraid-widget"}
        data-groupid={5}
        data-lang={lang}
      >
        {english ? 'Reservation' : 'Boka Bord'}
        
      </Global.bokabord>
    </Global.CenterDivRow>
  );
};

export default Bokabord;
